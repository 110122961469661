import { IconButton } from '@mui/material'
import React from 'react'
import BoltIcon from '@mui/icons-material/Bolt'
import PetIcon from '@mui/icons-material/Pets'
import StarIcon from '@mui/icons-material/Star'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const allIcons = [
  { name: 'Bolt', icon: <BoltIcon /> },
  { name: 'Pet', icon: <PetIcon /> },
  { name: 'Accessibility', icon: <AccessibilityIcon /> },
  { name: 'Star', icon: <StarIcon /> },
  { name: 'KeyboardArrowDown', icon: <KeyboardArrowDownIcon /> }
]

interface IconProps {
  name: string | undefined
}
const Icon: React.FC<IconProps> = (props: IconProps) => {
  if (props.name)
    return (
      allIcons.filter((iconEntry) => iconEntry.name === props.name)[0]
        ?.icon || <StarIcon />
    )
  return <StarIcon />
}

export default Icon
