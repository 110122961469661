import Default from '../layouts/default'
import React from 'react'
import { useNavigate } from 'react-router'
import GlobalContext from '../interface/global'
import { Container } from '@mui/material'
import ImageButton from '../components/imageButton'

interface HomePageProps {}

const HomePage: React.FC<React.PropsWithChildren<HomePageProps>> = (
  props: React.PropsWithChildren<HomePageProps>
) => {
  const games = React.useContext(GlobalContext).game
  const navigate = useNavigate()
  if (!games) return <Default title="HomePage">Keine Spiele vorhanden</Default>
  const selectGame = (part: number) => {
    games.set({ ...games, current: part })
    navigate('/legends')
  }
  return (
    <Default title="HomePage">
      <Container
        sx={{
          height: '100%',
          width: '100%',
          display: 'contents'
        }}
      >
        {games.list.map((game) => (
          <ImageButton
            key={game.part}
            title={game.name}
            url={game.url}
            onClick={() => selectGame(game.part)}
            height={`${(window.innerHeight - 56) / games.list.length}px`}
          ></ImageButton>
        ))}
      </Container>
    </Default>
  )
}

export default HomePage
