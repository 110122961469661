import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { MobileView, BrowserView } from 'react-device-detect'
import HomePage from './routes/homepage'
import Theme from './interface/color'
import { ThemeProvider } from '@mui/material'
import React, { useEffect } from 'react'
import GlobalContext, { GlobalContextInterface } from './interface/global'
import { get, set } from './services/local'
import HeroSelection from './routes/heroSelection'
import Overview from './routes/overview'
import Fight from './routes/fight'
import Legends from './routes/legends'
import Test from './routes/test'
import Settings from './routes/settings'

function App() {
  const context = React.useContext(GlobalContext)

  const [state, setState] = React.useState({ ...context })

  useEffect(() => {
    // console.log('Refresh')
    const value = get()
    if (value !== null) {
      setState({
        ...state,
        game: {
          ...state.game,
          current: value.game,
          activeLegend: value.legend
        },
        bosses: { ...state.bosses, bosses: value.bosses },
        heroes: { ...state.heroes, selected: value.heroes }
      })
    }
  }, [])

  useEffect(() => {
    // console.log('update localstorage')
    if (state.game.current > 0)
      set({
        bosses: state.bosses.bosses,
        game: state.game.current,
        heroes: state.heroes.selected,
        legend: state.game.activeLegend
      })
  }, [
    state.game.current,
    state.bosses.bosses,
    state.heroes.selected,
    state.game.activeLegend
  ])

  return (
    <ThemeProvider theme={Theme}>
      <GlobalContext.Provider
        value={{
          ...state,
          drawerSetOpen: (value) => setState({ ...state, drawerIsOpen: value }),

          fight: {
            ...state.fight,
            set: (fight) => setState({ ...state, fight })
          },
          game: {
            ...state.game,
            set: (game) => setState({ ...state, game })
          },
          bosses: {
            ...state.bosses,
            set: (bosses) => setState({ ...state, bosses })
          },
          heroes: {
            ...state.heroes,
            set: (heroes) => setState({ ...state, heroes })
          },
          set: (value) => setState({ ...value })
        }}
      >
        <MobileView>
          <BrowserRouter>
            <Routes>
              <Route path="/heroes" element={<HeroSelection />}></Route>
              <Route path="/overview" element={<Overview />}></Route>
              <Route path="/legends" element={<Legends />}></Route>
              <Route path="/settings" element={<Settings />}></Route>
              <Route path="/fight" element={<Fight />}></Route>
              <Route path="/test" element={<Test />}></Route>
              <Route path="" element={<HomePage />}></Route>
            </Routes>
          </BrowserRouter>
        </MobileView>
        <BrowserView>Please use a mobile device for this site</BrowserView>
      </GlobalContext.Provider>
    </ThemeProvider>
  )
}

export default App
