import { Card, Container, Typography, Avatar, IconButton } from '@mui/material'
import React, { useContext, useState } from 'react'
import FigureInterface from '../interface/figure'
import EditIcon from '@mui/icons-material/Edit'
import Popup from './popup'
import DiceButton from './diceButton'
import SkillButton from './skillButton'
import { translateSkill } from '../interface/heroes'
import GlobalContext from '../interface/global'
import { MonsterInterface } from '../interface/monster'
import DiceIcon from '@mui/icons-material/Casino'
import ChangeFigure from './changeFigure'

interface FigureProps {
  allowEdit?: boolean
  figure: FigureInterface
  fight?: boolean
  set?: (value: FigureInterface) => void
  onDiceRoll?: (value: number) => void
}
interface StateInterface {
  open: boolean
  dice?: number
}

const Figure: React.FC<FigureProps> = (props: FigureProps) => {
  const [state, setState] = useState<StateInterface>({ open: false })
  const global = useContext(GlobalContext)
  let style: { border?: string; backgroundColor?: string } = {}
  if (state.dice !== undefined && state.dice !== 0)
    style = { backgroundColor: '#d57e15' }

  const clickDice = (value: number) => {
    if (value === state.dice) value = 0
    if (props.onDiceRoll) props.onDiceRoll(value)
    setState({ ...state, dice: value })
  }

  const changeMonsterAfterSkillActive = (
    figure: FigureInterface,
    monster?: MonsterInterface
  ) => {
    if (global.fight.enemy) {
      global.set({
        ...global,
        fight: {
          ...global.fight,
          enemy: monster
        },
        heroes: {
          ...global.heroes,
          selected: [
            ...global.heroes.selected.map((h) =>
              props.figure.name === h.name ? figure : h
            )
          ]
        }
      })
    } else {
    }
  }

  const clickSkill = () => {
    if (props.figure.skill) {
      const skillChange = translateSkill(props.figure.skill?.skillMethod)
      console.log(skillChange)
      const enemy = global.fight.enemy
      if (enemy) {
        if (skillChange.monsterSp !== undefined) {
          if (!props.figure.skill.active) {
            const sp = enemy.stregth + (skillChange.monsterSp || 0)
            if (sp > 0 && sp < enemy.maxStrength) enemy.stregth = sp
            else enemy.stregth = 0
          } else {
            const sp = enemy.stregth - (skillChange.monsterSp || 0)
            if (sp > 0 && sp < enemy.maxStrength) enemy.stregth = sp
            else enemy.stregth = enemy.maxStrength
          }
        }
        if (skillChange.monsterWp !== undefined) {
          if (!props.figure.skill.active) {
            const wp = enemy.health + (skillChange.monsterWp || 0)
            enemy.health = wp
          } else {
            const wp = enemy.health - (skillChange.monsterWp || 0)
            if (wp > 0 && wp < enemy.maxHealth) enemy.health = wp
            else enemy.health = enemy.maxHealth
          }
        }
      }

      if (enemy) {
        if (!props.figure.skill.active) {
          changeMonsterAfterSkillActive(
            {
              ...props.figure,
              strength: props.figure.strength + (skillChange.sp || 0),
              health: props.figure.health + (skillChange.wp || 0),
              skill: {
                ...props.figure.skill,
                active: !!!props.figure.skill?.active
              }
            },
            {
              ...enemy
            }
          )
        } else {
          changeMonsterAfterSkillActive(
            {
              ...props.figure,
              strength: props.figure.strength - (skillChange.sp || 0),
              health: props.figure.health - (skillChange.wp || 0),
              skill: {
                ...props.figure.skill,
                active: !!!props.figure.skill?.active
              }
            },
            {
              ...enemy
            }
          )
        }
      }
    }
  }

  return (
    <Card
      sx={{
        margin: '10px',
        display: 'flex',
        padding: '10px',
        marginLeft: props.figure.type === 'summon' ? '40px' : undefined,
        justifyContent: 'space-between',
        ...style
      }}
    >
      <Container>
        <Typography variant="body1">
          {props.figure.name}{' '}
          {props.figure.attributes?.runeActive ? (
            <DiceIcon fontSize="small" />
          ) : (
            <></>
          )}
        </Typography>
        {!props.fight && props.figure.rank && props.figure.title ? (
          <Typography variant="body2">
            {props.figure.title} Rang {props.figure.rank}
          </Typography>
        ) : undefined}
        {!props.allowEdit && !props.fight ? (
          <Typography variant="body2">
            Stärke: {props.figure.maxStrength} Willenspunkte:{' '}
            {props.figure.maxHealth}
          </Typography>
        ) : undefined}
        {props.allowEdit || props.fight ? (
          <Typography variant="body2">
            Stärke: {props.figure.strength} Willenspunkte: {props.figure.health}
          </Typography>
        ) : undefined}
        <Typography variant="body2"></Typography>
        {props.fight ? (
          <DiceButton
            onChange={(value) => clickDice(value)}
            editable={
              props.figure.type === undefined || props.figure.type === 'hero'
            }
            values={
              !props.figure.attributes?.runeActive
                ? props.figure.dices
                : [6, 8, 10, 12]
            }
            value={state.dice}
          />
        ) : undefined}
      </Container>
      <div>
        <Avatar
          alt={props.figure.name}
          src={props.figure.icon}
          sx={{ width: 65, height: 65 }}
          onClick={() => setState({ ...state, open: true })}
        />
        {/* {props.allowEdit ? (
          <IconButton >
            <EditIcon />
          </IconButton>
        ) : undefined} */}
        {props.fight &&
        props.figure.skill &&
        props.figure.skill.icon &&
        props.figure.skill.type === 'action' ? (
          <SkillButton
            active={props.figure.skill.active}
            colors={props.figure.skill.colors}
            name={props.figure.skill.name}
            onClick={clickSkill}
            icon={props.figure?.skill.icon}
            type={props.figure.skill.type}
          />
        ) : undefined}
      </div>
      {state.open ? (
        <Popup
          title={props.figure.name}
          open={state.open}
          onClose={() => setState({ ...state, open: false })}
        >
          <ChangeFigure
            figure={props.figure}
            set={props.set}
            onClose={() => setState({ ...state, open: false })}
          />
        </Popup>
      ) : undefined}
    </Card>
  )
}

export default Figure
