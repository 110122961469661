export interface PathInterface {
  current: string
  list: PathEntryInterface[]
  set: (value: PathInterface) => void
}

export interface PathEntryInterface {
  url: string
  name: string
}

export const pathList: PathEntryInterface[] = [
  { url: '/', name: 'Start' },
  { url: '/heroes', name: 'Heldenauswahl' },
  { url: '/overview', name: 'Übersicht' }
]

export const path: PathInterface = {
  current: 'Start',
  list: pathList,
  set: (value) => {}
}
