import React from 'react'

export interface MonsterInterface {
  name: string
  stregth: number
  health: number
  maxHealth: number
  maxStrength: number
  legends: number[]
  set: (value: MonsterInterface) => void
}

export const monsterList: MonsterInterface[] = [
  {
    name: 'Gor',
    health: 4,
    stregth: 2,
    maxStrength: 2,
    maxHealth: 4,
    set: (value) => {},
    legends: [1, 2, 3]
  },
  {
    name: 'Skral',
    health: 6,
    stregth: 6,
    maxStrength: 6,
    maxHealth: 6,
    set: (value) => {},
    legends: [1, 2, 3]
  },
  {
    name: 'Troll',
    health: 12,
    stregth: 14,
    maxStrength: 14,
    maxHealth: 12,
    set: (value) => {},
    legends: [1, 2, 3]
  },
  {
    name: 'Wardrak',
    health: 7,
    stregth: 10,
    maxStrength: 10,
    maxHealth: 7,
    set: (value) => {},
    legends: [1, 2, 3]
  },

  {
    name: 'Geröll',
    health: 1,
    stregth: 0,
    maxStrength: 0,
    maxHealth: 1,
    set: (value) => {},
    legends: []
  }
]
