import React from 'react'
import { MonsterInterface } from './monster'

const bossList: MonsterInterface[] = [
  {
    name: 'Scral auf dem Turm',
    health: 6,
    stregth: 0,
    maxStrength: 0,
    maxHealth: 6,
    legends: [1],
    set: (value) => {}
  },
  {
    name: 'Der verhexte Gor',
    health: 7,
    stregth: 0,
    maxStrength: 0,
    maxHealth: 7,
    legends: [1],
    set: (value) => {}
  },
  {
    name: 'Der mächtige Troll',
    health: 20,
    stregth: 0,
    maxStrength: 0,
    maxHealth: 20,
    legends: [1],
    set: (value) => {}
  },
  {
    name: 'Der Dunkle Magier 7 WP',
    health: 7,
    stregth: 0,
    maxStrength: 0,
    maxHealth: 7,
    legends: [1],
    set: (value) => {}
  },
  {
    name: 'Der Dunkle Magier 14 WP',
    health: 14,
    stregth: 0,
    maxStrength: 0,
    maxHealth: 14,
    legends: [1],
    set: (value) => {}
  },
  {
    name: 'Benutzerdefinierter Boss',
    health: 0,
    stregth: 0,
    maxStrength: 0,
    maxHealth: 0,
    legends: [1, 2, 3],
    set: (value) => {}
  }
]

export interface BossInterface {
  bosses: MonsterInterface[]
  list: MonsterInterface[]
  set: (value: BossInterface) => void
}

export const bosses: BossInterface = {
  bosses: [],
  list: bossList,
  set: (value) => {}
}

export default bosses
