import { IconButton } from '@mui/material'
import React from 'react'
import Theme from '../interface/color'
import { SkillInterface } from '../interface/figure'
import Icon from './skillIcon'

interface SkillButtonProps extends SkillInterface {
  onClick: () => void
}
const SkillButton: React.FC<SkillButtonProps> = (props: SkillButtonProps) => {
  let color: string | undefined = ''
  if (props.active) color = props.colors?.active || Theme.palette.secondary.dark
  else color = props.colors?.inactive || Theme.palette.primary.dark
  return (
    <IconButton sx={{ color }} onClick={props.onClick}>
      <Icon name={props.icon} />
    </IconButton>
  )
}

export default SkillButton
