import React, { useState } from 'react'
import Container from '@mui/material/Container/Container'
import FigureInterface from '../interface/figure'
import Slider, { SliderThumb } from '@mui/material/Slider'
import FormControl from '@mui/material/FormControl'
import { Box, Button, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

interface ChangeFirgureProps {
  figure: FigureInterface
  set?: (figure: FigureInterface) => void
  onClose: () => void
}

interface AttrComponentProps {
  attr: number
  name: string
  setState: (value: number) => void
  max: number
  unit: string
}
const AttrComponent: React.FC<AttrComponentProps> = (
  props: AttrComponentProps
) => {
  const marks = [
    {
      value: 0,
      label: '0 ' + props.unit
    },
    {
      value: props.max,
      label: props.max + ' ' + props.unit
    },
    {
      value: props.attr,
      label: props.attr + ' ' + props.unit
    }
  ]
  return (
    <Box sx={{ marginBottom: '20px' }}>
      <Slider
        aria-label={props.name}
        value={props.attr}
        track={false}
        valueLabelDisplay="auto"
        slotProps={{ thumb: { style: { borderRadius: 0 } } }}
        marks={marks}
        min={0}
        max={props.max}
        onChange={(_, value) =>
          props.setState(typeof value === 'number' ? value : value[0])
        }
      />
      <Box>
        <Button
          sx={{ width: '50%' }}
          disabled={props.attr === props.max}
          onClick={() =>
            props.attr < props.max ? props.setState(props.attr - 1) : {}
          }
        >
          <RemoveIcon />
        </Button>
        <Button
          sx={{ width: '50%' }}
          disabled={props.attr === props.max}
          onClick={() =>
            props.attr < props.max ? props.setState(props.attr + 1) : {}
          }
        >
          <AddIcon />
        </Button>
      </Box>
    </Box>
  )
}

const ChangeFigure: React.FC<ChangeFirgureProps> = (
  props: ChangeFirgureProps
) => {
  const [state, setState] = useState({
    health: props.figure.health,
    strength: props.figure.strength
  })

  const save = () => {
    if (props.set !== undefined)
      props.set({
        ...props.figure,
        health: state.health,
        strength: state.strength
      })
    props.onClose()
  }

  return (
    <Container>
      <FormControl fullWidth sx={{ display: 'flex' }}>
        <AttrComponent
          attr={state.health}
          max={props.figure.maxHealth}
          name="Willenspunkte"
          unit="WP"
          setState={(value) => setState({ ...state, health: value })}
        />
        <AttrComponent
          attr={state.strength}
          max={props.figure.maxStrength}
          name="Stärkepunkte"
          unit="SP"
          setState={(value) => setState({ ...state, strength: value })}
        />
        <Button variant="contained" onClick={() => save()}>
          Übernehmen
        </Button>
      </FormControl>
    </Container>
  )
}

export default ChangeFigure
