import { Card, CardContent, Container } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Theme from '../interface/color'
import GlobalContext from '../interface/global'
import { MonsterInterface } from '../interface/monster'

interface MonsterProps {
  monster: MonsterInterface
  fight?: boolean
}
const Monster: React.FC<React.PropsWithChildren<MonsterProps>> = (
  props: React.PropsWithChildren<MonsterProps>
) => {
  const fight = useContext(GlobalContext).fight
  const monster = props.monster
  const navigate = useNavigate()
  const startFight = () => {
    fight?.set({ ...fight, enemy: { ...monster } })
    if (!props.fight) navigate('/fight')
  }

  return (
    <Card
      sx={{
        margin: '10px',
        display: 'block',
        border: `1px solid ${Theme.palette.primary.dark}`
      }}
      onClick={startFight}
    >
      <CardContent>
        <Container>{monster.name}</Container>
        <Container>
          <>
            SP: {monster.stregth} WP {monster.health}{' '}
          </>
        </Container>
        {props.children}
      </CardContent>
    </Card>
  )
}

export default Monster
