import Default from '../layouts/default'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Divider, Typography, Box } from '@mui/material'
import GlobalContext from '../interface/global'
import Monster from '../components/monster'
import DiceInput from '../components/diceInput'
import Popup from '../components/popup'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Figure from '../components/figure'
import { summons, translateSkill } from '../interface/heroes'
import FigureInterface from '../interface/figure'
import FightInterface from '../interface/fight'
import { MonsterInterface } from '../interface/monster'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

interface FightProps {}
interface Dice {
  name: string
  value: number
}

interface StateInterface {
  popup: boolean
  monsterDiceValue: number
  result: number
  dices: Dice[]
  summons: FigureInterface[]
}

const Fight: React.FC<FightProps> = (props: FightProps) => {
  const [state, setState] = useState<StateInterface>({
    popup: false,
    monsterDiceValue: 0,
    result: 0,
    dices: [],
    summons: []
  })
  const availableHeroes = useContext(GlobalContext).heroes.selected
  const heroes = useContext(GlobalContext).heroes
  const game = useContext(GlobalContext).game
  const fight = useContext(GlobalContext).fight
  const global = useContext(GlobalContext)
  const navigate = useNavigate()

  if (!fight || !fight.enemy) {
    return <Navigate to="/overview" state={{ from: useLocation() }} replace />
  }

  useEffect(() => {
    getHeroSummons()
  }, [])

  const handleFight = () => {
    if (fight.enemy) {
      const fightingHeroes = availableHeroes.filter((hero) =>
        state.dices.map((entry) => entry.name)?.includes(hero.name)
      )
      let heroStregth = fightingHeroes
        .map((hero) => hero.strength)
        .reduce((a, b) => a + b, 0)
      const heroDices = state.dices
        .map((entry) => entry.value)
        .reduce((sum, value) => sum + value, 0)
      heroStregth += heroDices
      const monsterStrength = fight.enemy.stregth + state.monsterDiceValue
      const result = heroStregth - monsterStrength
      // if the enemy was weakter
      if (result > 0) {
        fight.set({
          ...fight,
          enemy: { ...fight.enemy, health: fight.enemy.health - result }
        })
      } else if (result < 0) {
        // if the heroes were weaker
        // const fightingHeroes: FigureInterface[] = heroes.selected.map(
        //   (hero) => {
        //     if (!fight.selected?.includes(hero.name)) return hero
        //     return { ...hero, health: hero.health + result }
        //   }
        // )
      }

      setState({ ...state, result, popup: true })
    }
  }

  const heroDice = (name: string, value?: number) => {
    const inList = state.dices.map((entry) => entry.name).includes(name)
    if (inList && !value) {
      setState({
        ...state,
        dices: [...state.dices.filter((entry) => entry.name !== name)]
      })
    } else if (inList && value) {
      setState({
        ...state,
        dices: [
          ...state.dices.map((entry) =>
            entry.name === name ? { name, value } : entry
          )
        ]
      })
    } else if (value) {
      setState({
        ...state,
        dices: [...state.dices, { name, value }]
      })
    }
  }

  const getHeroSummons = () => {
    const summonHeroes = heroes.selected.filter(
      (hero) => hero.skill?.type === 'summon'
    )
    const existingSummons: FigureInterface[] = []
    for (const hero of summonHeroes) {
      for (const summon of summons) {
        if (hero.skill?.name === summon.name) existingSummons.push(summon)
      }
    }
    setState({ ...state, summons: existingSummons })
  }

  const getSummon = (name: string) => {
    const summon = state.summons.filter((summon) => summon.name === name)[0]
    if (summon)
      return (
        <Figure
          figure={summon}
          fight
          key={summon.name}
          onDiceRoll={(value) => heroDice(summon.name, value)}
        />
      )
    return undefined
  }

  const resetFight = () => {
    const skillInactiveHeroes = {
      ...heroes,
      selected: heroes.selected.map((hero) =>
        hero.skill?.active
          ? { ...hero, skill: { ...hero.skill, active: false } }
          : hero
      )
    }
    const emptyfight: FightInterface = {
      ...fight,
      enemy: undefined,
      selected: []
    }
    global.set({
      ...global,
      heroes: { ...skillInactiveHeroes },
      fight: emptyfight
    })
    navigate('/overview')
  }

  return (
    <Default title="Kampf">
      <Container>
        {availableHeroes.map((hero) => (
          <>
            <Figure
              figure={hero}
              fight
              key={hero.name}
              onDiceRoll={(value) => heroDice(hero.name, value)}
              set={(hero) =>
                heroes.set({
                  ...heroes,
                  selected: [
                    ...heroes.selected.map((h) =>
                      hero.name === h.name ? hero : h
                    )
                  ]
                })
              }
            />
            {hero.skill?.type === 'summon'
              ? getSummon(hero.skill.name)
              : undefined}
          </>
        ))}
      </Container>
      <Divider light={false} variant="middle" sx={{ margin: '20px' }} />
      <Container>
        <Monster monster={fight?.enemy}></Monster>
      </Container>
      <Divider light={false} variant="middle" sx={{ margin: '20px' }} />
      <Container>
        <DiceInput
          value={state.monsterDiceValue}
          setValue={(value) => setState({ ...state, monsterDiceValue: value })}
          name="Monster Würfel"
        />
      </Container>
      <Button
        disabled={state.dices.length === 0 || !state.monsterDiceValue}
        className="Button"
        variant="contained"
        onClick={handleFight}
      >
        Kampf
      </Button>
      {state.popup && state.result !== undefined ? (
        <Popup
          open={state.popup}
          onClose={() => setState({ ...state, popup: false })}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Das Kampfergebnis
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Das Ergebnis ist {state.result}.<br />
            {state.result >= 0
              ? `Das Monster verliert ${state.result} Willenspunkte.`
              : `Die Helden verlieren ${state.result} Willenspunkte.`}
          </Typography>
          {state.result > fight.enemy.health ? (
            <Button
              className="Button"
              variant="contained"
              onClick={() => navigate('/overview')}
            >
              Zurück zur Übersicht
            </Button>
          ) : (
            <Button
              className="Button"
              variant="contained"
              onClick={() => setState({ ...state, popup: false })}
            >
              Nochmal kämpfen
            </Button>
          )}
        </Popup>
      ) : undefined}
    </Default>
  )
}

export default Fight
