import * as React from 'react'
import { Button, Card, CardContent, Container, Drawer } from '@mui/material'
import GlobalContext from '../interface/global'
import IconButton from '@mui/material/IconButton/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { clear } from '../services/local'

interface MobileDrawerProps {}
const MobileDrawer: React.FC<React.PropsWithChildren<MobileDrawerProps>> = (
  props: React.PropsWithChildren<MobileDrawerProps>
) => {
  const global = React.useContext(GlobalContext)
  const navigate = useNavigate()
  const navigateAndClose = (href: string) => {
    global.drawerSetOpen(!global.drawerIsOpen)
    navigate(href)
  }

  const clearGame = () => {
    global.heroes.set({ ...global.heroes, selected: [] })
    global.bosses.set({ ...global.bosses, bosses: [] })
    global.game.set({ ...global.game, current: 0 })
    clear()
  }
  return (
    <>
      <Drawer
        open={global.drawerIsOpen}
        variant="persistent"
        anchor="left"
        hideBackdrop={true}
        onClose={(ev, reason) => {
          global.drawerSetOpen(!global.drawerIsOpen)
        }}
      >
        <Card sx={{ height: '100%', minWidth: '300px' }}>
          <CardContent>
            <IconButton
              sx={{ position: 'absolute', right: '15px' }}
              color="inherit"
              aria-label="open drawer"
              onClick={() => global.drawerSetOpen(!global.drawerIsOpen)}
              edge="start"
            >
              <CloseIcon />
            </IconButton>
            <Container sx={{ display: 'grid', marginTop: '40px' }}>
              <Button variant="outlined" onClick={() => navigateAndClose('/')}>
                Start
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigateAndClose('/legends')}
              >
                Legenden
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigateAndClose('/heroes')}
              >
                Helden Auswahl
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigateAndClose('/settings')}
              >
                Spiel Einstellungen
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigateAndClose('/overview')}
              >
                Übersicht
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigateAndClose('/fight')}
              >
                Kampf
              </Button>
              <Button variant="outlined" onClick={() => clearGame()} href="/">
                Zurücksetzen
              </Button>
            </Container>
          </CardContent>
        </Card>
      </Drawer>
    </>
  )
}

export default MobileDrawer
