import FigureInterface from '../interface/figure'
import { MonsterInterface } from '../interface/monster'

export interface Storage {
  game: number
  legend: number
  heroes: FigureInterface[]
  bosses: MonsterInterface[]
}

export const get = (): Storage | null => {
  const value = localStorage.getItem('storage')
  if (value) return JSON.parse(value)
  return null
}

export const set = (value: Storage) => {
  localStorage.setItem('storage', JSON.stringify(value))
}

export const clear = () => {
  localStorage.removeItem('storage')
}
