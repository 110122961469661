import Default from '../layouts/default'
import React, { useState } from 'react'
import HeroSelect from '../components/heroSelect'
import GlobalContext from '../interface/global'
import { Button, Container, IconButton, Paper, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { useNavigate } from 'react-router-dom'
import Figure from '../components/figure'
import FigureInterface from '../interface/figure'

interface HeroSelectionProps {}

const HeroSelection: React.FC<React.PropsWithChildren<HeroSelectionProps>> = (
  props: React.PropsWithChildren<HeroSelectionProps>
) => {
  const [state, setState] = useState<{ heroList: FigureInterface[] }>({
    heroList: []
  })
  const navigate = useNavigate()
  const game = React.useContext(GlobalContext).game
  const heroes = React.useContext(GlobalContext).heroes
  const heroesList = heroes.list.filter((hero) =>
    hero.legends.includes(game.activeLegend)
  )

  const addHero = (hero: FigureInterface) => {
    setState({ heroList: [...state.heroList, hero] })
  }

  const removeHero = (hero: FigureInterface) => {
    setState({
      heroList: state.heroList.filter((heroEntry) => hero.id !== heroEntry.id)
    })
  }

  console.log(state.heroList)

  const save = () => {
    heroes.set({ ...heroes, selected: state.heroList })
    navigate('/settings')
  }

  if (heroesList.length === 0)
    return (
      <Default title="Helden Auswahl">
        <Typography variant="h5">
          Für die Legende {game.activeLegend} sind aktuell keine Helden
          verfügbar.
        </Typography>
      </Default>
    )

  return (
    <Default title="Helden Auswahl">
      <Container sx={{ display: 'inline' }}>
        <Container
          sx={{
            position: 'fixed',
            top: '92%',
            zIndex: 5,
            height: '8%'
          }}
        >
          <Button
            disabled={state.heroList.length === 0}
            onClick={save}
            sx={{ width: '100%' }}
            variant="contained"
            startIcon={<CheckIcon />}
          >
            Weiter
          </Button>
        </Container>

        {heroesList.map((hero) => (
          <HeroSelect
            key={hero.id}
            hero={hero}
            active={
              state.heroList.filter(
                (selectedHero) => selectedHero.id === hero.id
              ).length === 1
            }
            addHero={addHero}
            removeHero={removeHero}
          ></HeroSelect>
        ))}
        <Paper sx={{ height: '40px' }}></Paper>
      </Container>
    </Default>
  )
}

export default HeroSelection
