import AppBar from '@mui/material/AppBar/AppBar'
import IconButton from '@mui/material/IconButton/IconButton'
import Toolbar from '@mui/material/Toolbar/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography/Typography'
import React from 'react'
import GlobalContext from '../interface/global'
import { useNavigate } from 'react-router'

interface AppBarProps {
  title: string
}
const MobileAppBar: React.FC<React.PropsWithChildren<AppBarProps>> = (
  props: React.PropsWithChildren<AppBarProps>
) => {
  const drawerContext = React.useContext(GlobalContext)
  const navigate = useNavigate()
  return (
    <>
      <AppBar position="static" enableColorOnDark>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() =>
              drawerContext.drawerSetOpen(!drawerContext.drawerIsOpen)
            }
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default MobileAppBar
