import Default from '../layouts/default'
import React from 'react'
import { Container } from '@mui/material'
import ImageButton from '../components/imageButton'
import { useNavigate } from 'react-router-dom'
import GlobalContext from '../interface/global'
import { legends } from '../interface/game'

interface LegendsProps {}

const Legends: React.FC<LegendsProps> = (props: LegendsProps) => {
  const navigate = useNavigate()
  const games = React.useContext(GlobalContext).game
  const legendNumbers =
    games.list.filter((game) => game.part === games.current)[0]?.legends || []
  const availableLegends = legends.filter((legend) =>
    legendNumbers.includes(legend.legend)
  )

  const selectLegend = (legend: number) => {
    games.set({ ...games, activeLegend: legend })
    navigate('/heroes')
  }

  return (
    <Default title="Legenden">
      <Container
        sx={{
          height: '100%',
          width: '100%',
          display: 'contents'
        }}
      >
        {availableLegends.map((legend) => (
          <ImageButton
            key={legend.legend}
            title={legend.name}
            url={legend.url || '/test'}
            onClick={() => selectLegend(legend.legend)}
            height={`${(window.innerHeight - 56) / availableLegends.length}px`}
          ></ImageButton>
        ))}
      </Container>
    </Default>
  )
}

export default Legends
