export interface GameAttrInterface {
  name: string
  part: number
  url: string
  legends: number[]
}

export interface LegendInterface {
  legend: number
  name: string
  url?: string
}

export interface GameInterface {
  current: number
  activeLegend: number
  list: GameAttrInterface[]
  set: (value: GameInterface) => void
}

export const legends: LegendInterface[] = [
  { legend: 1, name: 'Erste Legende' },
  { legend: 2, name: 'Zweite Legende' },
  { legend: 3, name: 'Dritte Legende' },
  { legend: 10, name: 'Zehnte Legende' }
]

export const gameList: GameAttrInterface[] = [
  {
    name: 'Teil 1',
    part: 1,
    url: '/images/part1.jpg',
    legends: [1, 2]
  },
  {
    name: 'Teil 2 - Die Reise in den Norden',
    part: 2,
    url: '/images/part2.jpg',
    legends: [3]
  },
  {
    name: 'Teil 3 - Die letzte Hoffnung',
    part: 3,
    url: '/images/part3.jpg',
    legends: [10]
  }
]

export const game: GameInterface = {
  current: 0,
  activeLegend: 0,
  list: gameList,
  set: (value) => {}
}
