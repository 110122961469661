import Default from '../layouts/default'
import React from 'react'
import FigureInterface from '../interface/figure'
import Figure from '../components/figure'
import { MonsterInterface } from '../interface/monster'
import Monster from '../components/monster'

interface OverviewProps {}

const Test: React.FC<React.PropsWithChildren<OverviewProps>> = (
  props: React.PropsWithChildren<OverviewProps>
) => {
  const hero: FigureInterface = {
    id: 1,
    name: 'Test',
    strength: 10,
    health: 10,
    maxStrength: 10,
    maxHealth: 20,
    title: 'Testtitle',
    rank: 15,
    legends: [1],
    dices: [1, 2, 3, 5],
    skill: {
      name: 'test',
      icon: 'Bolt',
      active: false,
      type: 'action',
      colors: { active: '#0cdeb0' }
    }
  }
  const monster: MonsterInterface = {
    name: 'Monster',
    stregth: 10,
    maxStrength: 10,
    health: 10,
    maxHealth: 20,
    legends: [1],
    set: (value) => {}
  }
  const boss: MonsterInterface = {
    name: 'Boss',
    stregth: 10,
    maxStrength: 10,
    health: 10,
    maxHealth: 20,
    legends: [1],
    set: (value) => {}
  }
  return (
    <Default title="Test">
      <Figure figure={hero} />
      <Figure figure={hero} allowEdit />
      <Figure figure={hero} fight />
      <Monster monster={monster} />
      <Monster monster={boss} />
    </Default>
  )
}

export default Test
