import { Button, ButtonGroup, TextField, Typography, Box } from '@mui/material'
import React, { useState } from 'react'
import Popup from './popup'
import EditIcon from '@mui/icons-material/Edit'
import { styled } from '@mui/system'

const Dice = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: '5px',
  minWidth: '30px',
  minHeigth: '30px'
}))

interface DiceButtonProps {
  values?: number[]
  onChange: (value: number) => void
  value?: number
  editable?: boolean
}
const DiceButton: React.FC<DiceButtonProps> = (props: DiceButtonProps) => {
  const [state, setState] = useState<{ open: boolean; custom?: number }>({
    open: false
  })
  let values
  if (props.values === undefined) values = [1, 2, 3, 4, 5, 6]
  else values = props.values
  return (
    <Box>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {values.slice(0, values.length / 2).map((value) => (
          <Dice
            sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
            color={
              props.value !== undefined && value === props.value
                ? 'primary'
                : 'info'
            }
            key={value}
            onClick={() => props.onChange(value)}
          >
            <Typography variant="caption">{value}</Typography>
          </Dice>
        ))}
      </ButtonGroup>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        {values.slice(values.length / 2, values.length).map((value) => (
          <Dice
            sx={{ borderTopLeftRadius: 0, borderRadiusTopRight: 0 }}
            color={
              props.value !== undefined && value === props.value
                ? 'primary'
                : 'info'
            }
            key={value}
            onClick={() => props.onChange(value)}
          >
            <Typography variant="caption">{value}</Typography>
          </Dice>
        ))}
        {props.editable ? (
          <Dice
            sx={{ padding: 0 }}
            color={
              props.value !== undefined && state.custom === props.value
                ? 'success'
                : 'info'
            }
            onClick={() => setState({ custom: undefined, open: true })}
          >
            {state.custom === undefined ? (
              <EditIcon fontSize={'small'} />
            ) : (
              state.custom
            )}
          </Dice>
        ) : undefined}
        <Popup
          onClose={() => setState({ ...state, open: false })}
          open={state.open}
          title="Würfelwert eingeben"
        >
          <TextField
            type="number"
            value={state.custom}
            onChange={(value) => {
              props.onChange(Number(value.target.value))
              setState({ ...state, custom: Number(value.target.value) })
            }}
          ></TextField>
        </Popup>
      </ButtonGroup>
    </Box>
  )
}

export default DiceButton
