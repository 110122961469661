import Default from '../layouts/default'
import React, { useState } from 'react'
import Container from '@mui/material/Container/Container'
import Hero from '../components/hero'
import { Button, Divider, IconButton } from '@mui/material'
import GlobalContext from '../interface/global'
import Monster from '../components/monster'
import AddIcon from '@mui/icons-material/Add'
import Popup from '../components/popup'
import BossSelection from '../components/bossSelection'
import FigureInterface from '../interface/figure'
import DiceIcon from '@mui/icons-material/Casino'
import FigureSelect from '../components/figureSelect'
import { relative } from 'path'
import Figure from '../components/figure'

interface OverviewProps {}

const Overview: React.FC<React.PropsWithChildren<OverviewProps>> = (
  props: React.PropsWithChildren<OverviewProps>
) => {
  const [state, setState] = useState({ bossPopup: false, runePopup: false })
  const global = React.useContext(GlobalContext)
  const heroes = global.heroes.selected
  const monsters =
    global.monsters?.filter((monster) =>
      monster.legends.includes(global.game.activeLegend)
    ) || []
  const bosses = global.bosses.list.filter((boss) =>
    global.bosses.bosses.map((b) => b.name).includes(boss.name)
  )

  const setHero = (hero: FigureInterface) => {
    global.heroes.set({
      ...global.heroes,
      selected: global.heroes.selected.map((h) =>
        hero.name === h.name ? hero : h
      )
    })
  }

  return (
    <Default title="Übersicht">
      <Container sx={{ marginTop: '10px' }}>
        Helden:
        {heroes.map((hero) => (
          // <Hero set={setHero} key={hero.name} hero={hero}></Hero>
          <Figure figure={hero} key={hero.name} allowEdit set={setHero} />
        ))}
      </Container>
      <Divider light={false} variant="middle" sx={{ margin: '20px' }} />
      <Container>
        Monster:
        {monsters?.map((monster) => (
          <Monster key={monster.name} monster={monster}></Monster>
        ))}
      </Container>
      <Divider light={false} variant="middle" sx={{ margin: '20px' }} />
      <Container>
        {bosses.length > 0 ? (
          bosses.map((boss) => (
            <Monster key={boss.name} monster={boss}></Monster>
          ))
        ) : (
          <></>
        )}
      </Container>
      <Container>
        <Button
          sx={{ width: '100%' }}
          variant="contained"
          onClick={() => setState({ ...state, bossPopup: true })}
        >
          <AddIcon />
          Boss hinzufügen
        </Button>
      </Container>
      <Container
        sx={{ position: 'absolute', top: '50px', left: '79%', width: '80px' }}
      >
        <IconButton onClick={() => setState({ ...state, runePopup: true })}>
          <DiceIcon sx={{ color: '#000000' }} />
        </IconButton>
      </Container>
      {state.bossPopup ? (
        <Popup
          open={state.bossPopup}
          onClose={() => setState({ ...state, bossPopup: false })}
        >
          <BossSelection
            onClose={() => setState({ ...state, bossPopup: false })}
          />
        </Popup>
      ) : (
        <></>
      )}
      {state.runePopup ? (
        <Popup
          open={state.runePopup}
          title="Runen Würfel"
          onClose={() => setState({ ...state, runePopup: false })}
        >
          <FigureSelect
            onClose={() => setState({ ...state, runePopup: false })}
          />
        </Popup>
      ) : (
        <></>
      )}
    </Default>
  )
}

export default Overview
