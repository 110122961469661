import React from 'react'
import FigureInterface from './figure'
import BoltIcon from '@mui/icons-material/Bolt'
import { maxHeaderSize } from 'http'

export interface SkillChange {
  wp?: number
  sp?: number
  monsterWp?: number
  monsterSp?: number
}

export const translateSkill = (skill?: string) => {
  if (!skill) return {}
  const [impact, attribute, value] = skill.split(';')
  const change: SkillChange = {}
  console.log(impact, attribute, value)
  switch (impact) {
    case 'hero':
      switch (attribute) {
        case 'sp':
          change.sp = parseInt(value)
          break
        case 'wp':
          change.wp = parseInt(value)
      }
      break
    case 'monster':
      switch (attribute) {
        case 'sp':
          change.monsterSp = parseInt(value)
          break
        case 'wp':
          change.monsterWp = parseInt(value)
      }
      break
  }
  return change
}

const heroList: FigureInterface[] = [
  {
    id: 1,
    name: 'Thorn',
    woman: 'Mairen',
    health: 7,
    rank: 14,
    strength: 1,
    title: 'Krieger',
    icon: '/images/thorn.jpeg',
    maxStrength: 14,
    maxHealth: 20,
    legends: [1, 2]
  },
  {
    id: 2,
    name: 'Kram',
    woman: 'Bait',
    health: 7,
    rank: 7,
    strength: 1,
    title: 'Zwerg',
    maxStrength: 14,
    maxHealth: 20,
    legends: [1]
  },
  {
    id: 3,
    name: 'Pasco',
    woman: 'Chada',
    health: 7,
    rank: 25,
    strength: 1,
    title: 'Bogenschützin',
    maxStrength: 14,
    maxHealth: 20,
    legends: [1, 2]
  },
  {
    id: 4,
    name: 'Liphardus',
    woman: 'Eara',
    health: 7,
    rank: 34,
    strength: 1,
    title: 'Zauberer',
    maxStrength: 14,
    maxHealth: 20,
    legends: [1, 2]
  },
  {
    id: 5,
    name: 'Thorn',
    woman: 'Mairen',
    health: 7,
    rank: 14,
    strength: 1,
    icon: '/images/thorn.jpeg',
    title: 'Krieger',
    maxStrength: 14,
    maxHealth: 19,
    legends: [3],
    skill: {
      name: 'Pferd',
      icon: 'Pets',
      active: false,
      colors: { active: '#FFFFF' },
      skillMethod: 'hero;sp;2',
      type: 'action'
    }
  },
  {
    id: 6,
    name: 'Kram',
    woman: 'Bait',
    health: 7,
    rank: 71,
    strength: 1,
    title: 'Zwerg',
    maxStrength: 14,
    maxHealth: 20,
    legends: [3]
  },
  {
    id: 7,
    name: 'Pasco',
    woman: 'Chada',
    health: 7,
    rank: 82,
    strength: 1,
    title: 'Bogenschütze',
    maxStrength: 14,
    maxHealth: 20,
    legends: [3]
  },
  {
    id: 8,
    name: 'Liphardus',
    woman: 'Eara',
    health: 7,
    rank: 34,
    strength: 1,
    title: 'Zauberer',
    maxStrength: 10,
    maxHealth: 14,
    legends: [3]
  },
  {
    id: 9,
    name: 'Stinner',
    woman: 'Stianna',
    health: 7,
    rank: 9,
    strength: 1,
    title: 'Seekrieger',
    maxStrength: 14,
    maxHealth: 20,
    legends: [2]
  },
  {
    id: 10,
    name: 'Fenn',
    woman: 'Fennah',
    health: 7,
    rank: 22,
    strength: 1,
    title: 'Fährtenleser',
    maxStrength: 10,
    maxHealth: 14,
    legends: [1, 2]
  },
  {
    id: 11,
    name: 'Kheelan',
    woman: 'Kheela',
    health: 7,
    rank: 29,
    strength: 1,
    title: 'Hüter',
    maxStrength: 14,
    maxHealth: 20,
    legends: [1, 2],
    skill: { name: 'Wassergeist', type: 'summon' }
  },
  {
    id: 12,
    name: 'Arborn',
    woman: 'Talvora',
    health: 7,
    rank: 50,
    strength: 1,
    title: 'Bewahrer',
    maxStrength: 14,
    maxHealth: 20,
    legends: [1],
    skill: {
      name: 'Schächung',
      type: 'action',
      skillMethod: 'monster;sp;-4',
      active: false,
      icon: 'KeyboardArrowDown',
      colors: { active: '#54504c' }
    }
  },
  {
    id: 23,
    name: 'Arborn',
    woman: 'Talvora',
    health: 7,
    rank: 50,
    strength: 1,
    title: 'Bewahrer',
    maxStrength: 14,
    maxHealth: 20,
    legends: [2],
    skill: {
      name: 'Schächung',
      type: 'action',
      skillMethod: 'monster;sp;-2',
      active: false,
      icon: 'KeyboardArrowDown',
      colors: { active: '#54504c' }
    }
  },
  {
    id: 13,
    name: 'Bragor',
    woman: 'Rhega',
    health: 7,
    rank: 15,
    strength: 1,
    title: 'Tarus',
    maxStrength: 14,
    maxHealth: 19,
    legends: [1, 2]
  },
  {
    id: 14,
    name: 'Darhen',
    woman: 'Darh',
    health: 7,
    rank: 68,
    strength: 1,
    title: 'Beschwörer',
    maxStrength: 14,
    maxHealth: 15,
    legends: [1, 2, 3],
    skill: {
      name: 'Knochengolem',
      active: false,
      colors: { active: '#CD9B1D' },
      type: 'summon'
    }
  },
  {
    id: 15,
    name: 'Drukil',
    woman: 'Drukia',
    health: 7,
    rank: 23,
    strength: 1,
    title: 'Hautwandler',
    maxStrength: 6,
    maxHealth: 14,
    legends: [1, 2, 3],
    skill: {
      name: '',
      active: false,
      colors: { active: '#522c07' },
      type: 'action',
      skillMethod: 'hero;sp:0' // todo
    }
  },
  {
    id: 16,
    name: 'Leander',
    woman: 'Leane',
    health: 7,
    rank: 4,
    strength: 1,
    title: 'Seher',
    maxStrength: 14,
    maxHealth: 14,
    legends: [1, 2, 3]
  },
  {
    id: 17,
    name: 'Forn',
    woman: 'Forr',
    health: 7,
    rank: 37,
    strength: 1,
    title: 'Halbskral',
    maxStrength: 14,
    maxHealth: 20,
    legends: [1, 2, 3]
  },
  {
    id: 18,
    name: 'Aćhan',
    woman: 'Aćh',
    health: 7,
    rank: 26,
    strength: 1,
    title: 'Takuri-Hüter',
    maxStrength: 10,
    maxHealth: 19,
    legends: [1, 2, 3],
    skill: { name: 'Takuri', type: 'summon' }
  },
  {
    id: 19,
    name: 'Ilar',
    woman: 'Iril',
    health: 7,
    rank: 52,
    strength: 1,
    title: 'Runenmeister',
    maxStrength: 12,
    maxHealth: 20,
    legends: [1, 2, 3],
    dices: [1, 2, 3, 4, 5]
  },
  {
    id: 20,
    name: 'Ijsdur',
    woman: 'Ijsdora',
    health: 7,
    rank: 5,
    strength: 1,
    title: 'Eis-Dämon',
    maxStrength: 12,
    maxHealth: 13,
    legends: [1, 2, 3],
    dices: [1, 2, 3, 4],
    skill: {
      name: 'Kampfblitz',
      icon: 'Bolt',
      active: false,
      colors: { active: '#0cdeb0' },
      skillMethod: 'hero;sp;5',
      type: 'action'
    }
  },
  {
    id: 21,
    name: 'Barz',
    woman: 'Yaza',
    health: 7,
    rank: 38,
    strength: 1,
    title: 'Steppennomade',
    maxStrength: 14,
    maxHealth: 20,
    legends: [1, 2, 3],
    skill: {
      name: 'Schwächungspulver',
      icon: 'Star',
      active: false,
      colors: { active: '#104E8B' },
      skillMethod: 'monster;wp;-3',
      type: 'action'
    }
  },
  {
    id: 22,
    name: 'Orfen',
    woman: 'Marfa',
    health: 7,
    rank: 6,
    strength: 1,
    title: 'Wolfskrieger',
    maxStrength: 14,
    maxHealth: 20,
    legends: [1, 2]
  }
]

export const summons: FigureInterface[] = [
  {
    id: 111,
    name: 'Wassergeist',
    strength: 0,
    health: 0,
    maxHealth: 0,
    maxStrength: 0,
    dices: [4, 5, 6, 7],
    legends: [],
    type: 'summon'
  },
  {
    id: 114,
    name: 'Knochengolem',
    maxStrength: 0,
    maxHealth: 0,
    strength: 0,
    health: 0,
    dices: [6],
    legends: [],
    type: 'summon'
  },
  {
    id: 118,
    name: 'Takuri',
    maxStrength: 0,
    maxHealth: 0,
    strength: 0,
    health: 0,
    dices: [2, 3, 5, 7],
    legends: [],
    type: 'summon'
  }
] 

export interface HeroInterface {
  list: FigureInterface[]
  selected: FigureInterface[]
  set: (value: HeroInterface) => void
}

const heroes: HeroInterface = {
  list: heroList,
  selected: [],
  set: (value) => {}
}

export default heroes
