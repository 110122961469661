import { Container, FormControl, MenuItem, Select, Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import GlobalContext from '../interface/global'
import HeroSelection from '../routes/heroSelection'

interface FigureSelect {
  onClose: () => void
}
const FigureSelect: React.FC<FigureSelect> = (props: FigureSelect) => {
  const heroes = useContext(GlobalContext).heroes
  const [state, setState] = useState<{
    figure: string | undefined
  }>({
    figure: 'Keiner'
  })

  useEffect(() => {
    const runeHero = heroes.selected.filter(
      (hero) => hero.attributes?.runeActive
    )
    if (runeHero.length === 1) {
      setState({ figure: runeHero[0].name })
    }
  }, [])

  const allHeroes = heroes.selected.map((hero) => hero.name)
  allHeroes.push('Keiner')

  const selectFigure = () => {
    heroes.set({
      ...heroes,
      selected: heroes.selected.map((hero) =>
        state.figure === hero.name
          ? { ...hero, attributes: { runeActive: true } }
          : { ...hero, attributes: { runeActive: false } }
      )
    })
    props.onClose()
  }

  return (
    <Container sx={{ width: '100%', padding: 0 }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'space-between',
          padding: '15px'
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <Container sx={{ width: '100%', display: 'flex' }}>
            <Select
              id="select-boss"
              value={state.figure}
              sx={{ width: '100%' }}
              onChange={(event) => setState({ figure: event.target.value })}
            >
              {allHeroes.map((figure, index) => (
                <MenuItem key={figure + index.toString()} value={figure}>
                  {figure}
                </MenuItem>
              ))}
            </Select>
          </Container>

          {state.figure !== undefined ? (
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center'
                justifyContent: 'space-between'
              }}
            >
              <Button
                sx={{ marginTop: '20px' }}
                variant={'contained'}
                onClick={() => selectFigure()}
              >
                Übernehmen
              </Button>
            </Container>
          ) : null}
        </FormControl>
      </Container>
    </Container>
  )
}

export default FigureSelect
