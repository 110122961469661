import { Container, Modal, Paper, TextField, Typography } from '@mui/material'
import React from 'react'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

interface PopupProps {
  open: boolean
  onClose: () => void
  title?: string
}
const Popup: React.FC<React.PropsWithChildren<PopupProps>> = (
  props: React.PropsWithChildren<PopupProps>
) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        {props.title ? (
          <Typography id="modal-modal-title" variant="h4" component="h2">
            {props.title}
          </Typography>
        ) : undefined}
        {props.children}
      </Paper>
    </Modal>
  )
}

export default Popup
