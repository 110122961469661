import { Avatar, Card, Checkbox, Container, Typography } from '@mui/material'
import React, { useContext } from 'react'
import FigureInterface from '../interface/figure'
import GlobalContext from '../interface/global'

interface HeroeSelectProps {
  hero: FigureInterface
  active: boolean
  addHero: (hero: FigureInterface) => void
  removeHero: (hero: FigureInterface) => void
}
const HeroSelect: React.FC<React.PropsWithChildren<HeroeSelectProps>> = (
  props: React.PropsWithChildren<HeroeSelectProps>
) => {
  // const heroes = useContext(GlobalContext).heroes

  const switchHero = () => {
    if (props.active) props.removeHero(props.hero)
      else props.addHero(props.hero)
  }

  console.log(props)

  return (
    <Card
      sx={{
        margin: '10px',
        display: 'flex',
        padding: '10px',
        justifyContent: 'space-between'
      }}
    >
      <Container sx={{}}>
        <Typography variant="body1">{props.hero.name}</Typography>
        <Typography variant="body2">
          <>
            {props.hero.title} Rang {props.hero.rank}
          </>
        </Typography>
        <Typography variant="body2">
          <>
            Stärke: {props.hero.maxStrength} Willenspunkte:{' '}
            {props.hero.maxHealth}
          </>
        </Typography>
      </Container>
      <div>
        <Avatar src={props.hero.icon}></Avatar>
        <Checkbox checked={props.active} onChange={switchHero}></Checkbox>
      </div>
    </Card>
  )
}

export default HeroSelect
