import { MonsterInterface } from './monster'

interface FightInterface {
  enemy?: MonsterInterface
  selected?: string[]
  set: (value: FightInterface) => void
}

export const fight: FightInterface = { set: (value) => {} }

export default FightInterface
