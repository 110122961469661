import React from 'react'
import MobileAppBar from '../components/mobileAppBar'
import MobileDrawer from '../components/mobileDrawer'
import { Container, styled } from '@mui/material'

const Content = styled('span')(({ theme }) => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center'
}))

interface DefaultProps {
  title: string
}
const Default: React.FC<React.PropsWithChildren<DefaultProps>> = (
  props: React.PropsWithChildren<DefaultProps>
) => {

  return (
    <Content className="Content">
      <MobileAppBar title={props.title}>AppName</MobileAppBar>
      <MobileDrawer />
      {props.children}
    </Content>
  )
}

export default Default
