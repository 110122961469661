import Default from '../layouts/default'
import React from 'react'
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button
} from '@mui/material'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import CheckIcon from '@mui/icons-material/Check'
import { useNavigate } from 'react-router-dom'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}))

interface SettingsItemProps {
  label: string
  value: number
  onChange: (value: number) => void
}

const SettingItem: React.FC<React.PropsWithChildren<SettingsItemProps>> = (
  props: React.PropsWithChildren<SettingsItemProps>
) => {
  return (
    <Box sx={{ paddingTop: '15px' }}>
      <FormControl fullWidth>
        <InputLabel id={props.label + 'label'}>{props.label}</InputLabel>
        <Select
          labelId={props.label + 'label'}
          id={props.label}
          value={props.value}
          label={props.label}
          onChange={(event) =>
            props.onChange(parseInt(event.target.value + ''))
          }
        >
          {props.children}
        </Select>
      </FormControl>
    </Box>
  )
}

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = (props: SettingsProps) => {
  const navigate = useNavigate()
  return (
    <Default title="Legenden">
      <Container
        sx={{
          height: '100%',
          width: '100%',
          display: 'contents'
        }}
      >
        <Item>
          <Container
            sx={{
              position: 'fixed',
              top: '92%',
              zIndex: 5,
              height: '8%'
            }}
          >
            <Button
              onClick={() => navigate('/overview')}
              sx={{ width: '100%' }}
              variant="contained"
              startIcon={<CheckIcon />}
            >
              Weiter
            </Button>
          </Container>
          Schwierigkeit
          <Box>
            <SettingItem
              label="Schwierigkeitstufe"
              value={4}
              onChange={() => {}}
            >
              <MenuItem value={4}>4 Spieler</MenuItem>
              <MenuItem value={5}>5 Spieler</MenuItem>
              <MenuItem value={6}>6 Spieler</MenuItem>
            </SettingItem>
            <SettingItem label="Handicap" value={0} onChange={() => {}}>
              <MenuItem value={0}>Keiner</MenuItem>
              <MenuItem value={1}>Schwarzer Herold</MenuItem>
              <MenuItem value={2}>Bannkreis von Koranad</MenuItem>
            </SettingItem>
          </Box>
        </Item>
      </Container>
    </Default>
  )
}

export default Settings
