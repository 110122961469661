import {
  Container,
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Button
} from '@mui/material'
import React, { useContext, useState } from 'react'
import GlobalContext from '../interface/global'
import { MonsterInterface } from '../interface/monster'

interface BossSelectionProps {
  onClose: () => void
}
const BossSelection: React.FC<React.PropsWithChildren<BossSelectionProps>> = (
  props: React.PropsWithChildren<BossSelectionProps>
) => {
  const bossContext = useContext(GlobalContext).bosses
  const game = useContext(GlobalContext).game
  const [state, setState] = useState<{
    boss: MonsterInterface | undefined
  }>({
    boss: undefined
  })

  // the boss gets selected from the select menu
  const selectBoss = (value: string) => {
    const boss = bossContext.list.filter((b) => b.name === value)[0]
    setState({ ...state, boss })
  }

  const addBoss = () => {
    if (state.boss) {
      bossContext.set({
        ...bossContext,
        bosses: [...bossContext.bosses, state.boss]
      })
    }
    props.onClose()
  }
  const allBosses = bossContext.list.filter(
    (boss) =>
      boss.legends.includes(game.activeLegend) &&
      !bossContext.bosses.map((b) => b.name).includes(boss.name)
  )

  const bossNames = allBosses.map((boss) => boss.name)
  console.log(state)
  return (
    <Container sx={{ width: '100%', padding: 0 }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'space-between',
          padding: '15px'
        }}
      >
        <FormControl sx={{ width: '100%' }}>
          <Container sx={{ width: '100%', display: 'flex' }}>
            <Select
              id="select-boss"
              value={state.boss?.name}
              sx={{ width: '100%' }}
              onChange={(event) => selectBoss(event.target.value)}
            >
              {bossNames.map((boss, index) => (
                <MenuItem key={boss + index.toString()} value={boss}>
                  {boss}
                </MenuItem>
              ))}
            </Select>
          </Container>

          {state.boss !== undefined ? (
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center'
                justifyContent: 'space-between'
              }}
            >
              <TextField
                label="Willenspunkte"
                variant="outlined"
                type={'number'}
                sx={{ marginTop: '20px' }}
                value={state.boss.health}
                onChange={(event) => {
                  {
                    if (state.boss)
                      setState({
                        ...state,
                        boss: {
                          ...state.boss,
                          health: Number(event.target.value)
                        }
                      })
                  }
                }}
              />
              <TextField
                sx={{ marginTop: '20px' }}
                label="Stärkepunkte"
                variant="outlined"
                type={'number'}
                value={state.boss.stregth}
                onChange={(event) => {
                  {
                    if (state.boss)
                      setState({
                        ...state,
                        boss: {
                          ...state.boss,
                          stregth: Number(event.target.value)
                        }
                      })
                  }
                }}
              />
              <Button
                sx={{ marginTop: '20px' }}
                variant={'contained'}
                onClick={addBoss}
              >
                Hinzufügen
              </Button>
            </Container>
          ) : null}
        </FormControl>
      </Container>
    </Container>
  )
}

export default BossSelection
