import React from 'react'
import bosses, { BossInterface } from './bosses'
import DrawerInterface from './drawer'
import FightInterface, { fight } from './fight'
import { GameInterface, game } from './game'
import heroes, { HeroInterface } from './heroes'
import { MonsterInterface, monsterList } from './monster'
import { PathInterface, path } from './path'

export interface GlobalContextInterface {
  heroes: HeroInterface
  bosses: BossInterface
  game: GameInterface
  fight: FightInterface
  drawer?: DrawerInterface
  monsters?: MonsterInterface[]
  drawerIsOpen: boolean
  drawerSetOpen: (value: boolean) => void
  path: PathInterface
  set: (value: GlobalContextInterface) => void
}

const GlobalContext = React.createContext<GlobalContextInterface>({
  heroes: heroes,
  bosses: bosses,
  game: game,
  fight: fight,
  drawer: { isOpen: false },
  monsters: monsterList,
  drawerIsOpen: false,
  drawerSetOpen: (value: boolean) => {},
  path: path,
  set: (value) => {}
})
GlobalContext.displayName = 'GlobalContext'

export default GlobalContext
