import { Container, Paper, TextField } from '@mui/material'
import React from 'react'

interface DiceInputProps {
  name?: string
  value?: number
  setValue?: (value: number) => void
}
const DiceInput: React.FC<React.PropsWithChildren<DiceInputProps>> = (
  props: React.PropsWithChildren<DiceInputProps>
) => {
  return (
    <Paper sx={{ margin: '10px', padding: '0 0 10px 0' }}>
      <Container>
        <TextField
          id="standard-required"
          type="number"
          label={props.name || 'Würfelwert'}
          variant="standard"
          value={props.value || ''}
          onChange={(value) =>
            props.setValue
              ? props.setValue(Number(value.currentTarget.value))
              : undefined
          }
        />
      </Container>
    </Paper>
  )
}

export default DiceInput
