import createTheme from '@mui/material/styles/createTheme'
import { amber, green, red, grey } from '@mui/material/colors'

const Theme = createTheme({
  palette: {
    primary: {
      main: '#bc5626' // main color for the app
    },
    secondary: {
      main: green[400] // secondary color for the app
    },
    info: {
      main: grey[50]
    }
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: '3rem'
    },
    h2: {
      fontWeight: 500,
      fontSize: '2.5rem'
    },
    h3: {
      fontWeight: 500,
      fontSize: '2rem'
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.5rem'
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.25rem'
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem'
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.25rem'
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '1rem'
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.5
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.7rem',
      lineHeight: 1.5
    },
    button: {
      fontWeight: 500,
      fontSize: '1rem',
      textTransform: 'none'
    }
  }
})

export default Theme
